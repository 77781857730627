import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.instanode-headstart-thumbimage')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  // smoothScroll('a[href^="#"]', {offset: 0})

  if(window.location.pathname == '/' ){
    smoothScroll('a[href*="/#"]', {offset: 120})
  };

    $('.services--item').click(function(){ //hover  can be used
        $(this).toggleClass('flipped');
    });

    $(document).scroll(function () {
        var scroll = $(this).scrollTop()
        if ($(window).width() >= 1024 && scroll > 800) {
          $('.callout-page .header--wrapper').addClass('fixed');
        } else {
          $('.callout-page .header--wrapper').removeClass('fixed');
        };
    });

       // close burger menu if cklcik on id to show target
    $('.header-nav-list li a').click(function(){
        $(".header-nav-content").collapse('hide');
    });
});


$(window).scroll(function () {
    var position = window.pageYOffset;
    $('.grid-content').each(function () {
      var target = $(this).offset().top;
      var id = $(this).attr('id');
      var navLinksHome = $('.header-nav-list li a');
      if (position > target - 200 && id) {
        navLinksHome.removeClass('current');
        $('.header-nav-list li a[href*="#' + id + '"]').addClass('current');
      } else if ($(window).scrollTop() === 0) {
        navLinksHome.removeClass('current');
      }
      else {
        $('.header-nav-list li a current').removeClass('current');
      }
    });
 });


